/* FONTS ___________________________________________ */
h1 {
  font-size: calc(25px + 2.7vw);
}

h2 {
  font-size: calc(10px + 2vw);
}

p {
  font-size: calc(10px + 0.5vw);
}

@font-face {
  font-family: "Luloclean";
  src: url("./fonts/LULO/luloclean.ttf") format("truetype");
}

.lulo {
  font-family: "Luloclean";
  letter-spacing: -0.1rem;
}

.helv {
  font-family: "Helvetica";
}

/* BUTTON STYLING __________________________________ */
.button {
  background-color: #181829;
  border: 0px;
  color: white;
  padding: 0.8rem 2rem;
  transition: 0.25s;
}

.button:hover,
.button:focus {
  box-shadow: inset 0 0 0 2rem rgb(251, 147, 52);
}

/* COLORS __________________________________________ */
div.whi {
  background-color: white;
}

.whi:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: white;
}

div.nav {
  background-color: #181829;
}

div.o-tech {
  background-color: #181829;
}

.Nutr-Loop {
  background-color: rgb(22, 112, 112);
}

.head-img {
  background-image: url("./components/pages/assets/home_background.png");
  background-position: -95px -20px;
  background-size: 2000px;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.Citi-img {
  background-image: url("./components/pages/assets/cities.jpg");
  background-position: -0px 0px;
  background-size: 2000px;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.cit-text {
  background-color: #181829;
}

.role-butt {
  background-color: #181829;
  color: white;
  height: 40px;
}

.role-but:hover {
  color: rgb(251, 147, 52);
}

.card-graphic {
  background-color: rgb(22, 112, 112);
}

.crd {
  background-color: #579692;
}

.hrt {
  padding: 10px;
  height: 10px;
  width: 50px;
  margin-left: 75px;
  color: aliceblue;
}

.nav:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: #181829;
}

div.yel {
  background-color: rgb(251, 147, 52);
}

.yel:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: rgb(251, 147, 52);
}

div.gre {
  background-color: #e7ff6e;
}

.gre:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: #e7ff6e;
}

div.tli {
  background-color: rgb(70, 133, 125);
}

.tli:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: rgb(70, 133, 125);
}

div.tda {
  background-color: rgb(22, 112, 112);
}

.tda:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: rgb(22, 112, 112);
}

.navbar {
  position: absolute;
  top: 0;
  left: 3vw;
  z-index: 2;
}

.navbar-brand {
  background-color: transparent;
  font-family: Helvetica;
  font-size: 40px;
  margin-left: 1.5vw;
}

.navbar-nav {
  font-family: Helvetica;
  color: rgb(255, 255, 255);
}

.nav-link:hover {
  color: rgb(255, 174, 0);
}

.nav-link {
  font-family: Helvetica;
  margin-right: 10px;
}

.nav-link-a {
  color: #e7ff6e;
}

.main-footer {
  background-color: #181829;
}

.col {
  justify-content: space-evenly;
}

.footer-container {
  /* display: flex; */
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

mark.red {
  color: #e7ff6e;
  background: none;
}

.main-logo {
  height: 69px;
  position: relative;
}

/* jose css  */
div.drktl {
  background-color: #154954;
}

.gre:is(h1, h2, h3, h4, h5, h6, p, span) {
  color: #e7ff6e;
}

.transparent {
  background-color: transparent;
}

.bold {
  font-weight: bold;
}

.non-home-background-container {
  width: 100%;
  height: 235px;
  overflow: hidden;
}

.non-home-background-container ing {
  margin-top: -100px;
}

/* Mohameds logo styling */
/* OTHER ELEMENTS */
.challengebox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 20px 20px 0px 0px;
  width: 250px;
  height: 650px;
  transition: 200ms ease-in-out;
}
.challengebox:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

#mowdesc,
#eiddesc,
#eotbdesc {
  text-align: left;
  opacity: 0%;
}
#mow,
#eid,
#eotb {
  padding-left: 20px;
  padding-right: 20px;
}
#mowtext,
#eidtext,
#eotbtext {
  margin-top: -10px;
}
#mow:hover > #mowtext {
  transition: 300ms linear;
  color: #e7ff6e;
}
#mow:hover > #mowdesc {
  transition: 300ms linear;
  opacity: 100%;
}
#eid:hover > #eidtext {
  transition: 300ms linear;
  color: #e7ff6e;
}
#eid:hover > #eiddesc {
  transition: 300ms linear;
  opacity: 100%;
}
#eotb:hover > #eotbtext {
  transition: 300ms linear;
  color: #e7ff6e;
}
#eotb:hover > #eotbdesc {
  transition: 300ms linear;
  opacity: 100%;
}

#impacthex,
#endproducthex,
#visionhex,
#uniquevaluehex,
#missionhex,
#solutionhex {
  scale: 1200%;
  color: rgb(251, 147, 52);
  rotate: 90deg;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
  margin-bottom: 20px;
  transition: 150ms linear;
}
#hexagons {
  width: 50%;
}
.left,
.right {
  height: 500px;
  width: 175px;
}
.center {
  width: 175px;
  height: 600px;
  margin-top: -50px;
  margin-left: 0px;
  margin-right: 0px;
}
#impact {
  padding-top: 17px;
  padding-left: 40px;
  height: 170px;
  margin-top: 70px;
  margin-bottom: 15px;
}
#endproduct {
  height: 170px;
  padding-top: 17px;
  padding-left: 40px;
  margin-bottom: 100px;
}
#vision {
  padding-top: 17px;
  padding-left: 40px;
  height: 170px;
  margin-top: 30px;
}
#uniquevalue {
  height: 170px;
  padding-left: 40px;
  padding-top: 17px;
  margin-bottom: -158px;
}
#mission {
  height: 170px;
  padding-top: 17px;
  padding-left: 40px;
  margin-top: 70px;
  margin-bottom: 15px;
}
#solution {
  height: 170px;
  padding-top: 17px;
  padding-left: 40px;
  margin-bottom: 80px;
}
#chevron {
  scale: 1000%;
}
#thechallenge,
#blacksoldierfly {
  padding: 5vw;
  padding-top: 100px;
}
#mow,
#eid,
#eotb {
  margin: 1.2vw;
}
#fly {
  margin-top: -100px;
}
#firstfly {
  width: 65%;
  object-fit: cover;
  height: 325px;
}
.abouthighlight {
  padding: 2vw;
}
#infographic {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: 10vw;
  margin-bottom: 120px;
}
#darkbg {
  height: 30vw;
  margin-top: -3.5vw;
}
#text {
  margin-top: -20vw;
  width: 30%;
}
#aboutbottom {
  width: 70%;
  object-fit: cover;
  margin-left: 15vw;
  height: 300px;
}
.form-control {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  color: white;
}
.form-control:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.form-control:after {
  background-color: #181829;
}
#form {
  padding: 50px 30px 10px 30px;
}
hr {
  border: 0;
  clear: both;
  display: block;
  background-color: white;
  height: 1.5px;
  opacity: 1;
  animation: horline 500ms ease-in-out;
}
@keyframes horline {
  0% {
    width: 20%;
    opacity: 0.5;
  }
}
/* #bbs:hover > #bbsicon,
#tre:hover > #treicon,
#ficm:hover > #ficmicon,
#boc:hover > #bocicon,
#ror:hover > #roricon {
  color: white;
  scale: 560%; */
/* } */
#bbsicon,
#treicon,
#ficmicon,
#bocicon,
#roricon {
  scale: 500%;
  margin: 60px 10px 60px 10px;
  color: #e7ff6e;
  transition: 500ms ease-in-out;
}
#form,
#generalinfo {
  width: 65%;
}
#benefiticons {
  width: 75%;
}
#photopg {
  width: 60%;
}
#impactsection {
  width: 50%;
  padding-left: 10vw;
}
@media only screen and (max-width: 877px) {
  #form,
  #generalinfo {
    width: 90%;
  }
  #benefiticons {
    width: 90%;
  }
  #photopg {
    width: 90%;
  }
  #impactsection {
    padding-left: 5vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }
  .challengebox {
    left: 0;
    border-radius: 20px 20px 20px 20px;
  }
  #challenges {
    display: flex;
    flex-direction: column;
  }
  #tray {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  #mow,
  #eid,
  #eotb {
    width: 100%;
    margin-bottom: 60px;
  }
  #firstfly {
    width: 100%;
    scale: 100%;
  }
  #infographic {
    flex-direction: column;
    justify-content: center;
  }
  #hexagons {
    width: 70%;
    scale: 90%;
  }
  #infographic {
    margin-top: -200px;
    margin-bottom: 200px;
  }
  #darkbg {
    height: 30vw;
    margin-top: 20vw;
  }
  #text {
    margin-top: 4vw;
    margin-bottom: 4vw;
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  #firstfly {
    width: 100%;
    object-fit: fill;
    height: 270px;
  }
  #hexagons {
    width: 100%;
    scale: 80%;
  }
  #infographic {
    margin-top: -300px;
  }
  #darkbg {
    margin-top: -10vw;
    height: 40vw;
  }
  #aboutbottom {
    width: 100%;
  }
}
